<template>
  <div class="shopContainer">
    <el-dialog
        :before-close="handleClose"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="dialogVisible"
        center
        width="656px">
      <el-form ref="formRef" :model="$parent.shopForm" :rules="rules" label-width="120px">
        <el-form-item :label="$fanyi('店铺类型') + '：'" prop="shop_type">
          <el-select v-model="$parent.shopForm.shop_type">
            <el-option
                v-for="item in shopTypeOption"
                :key="item.value"
                :label="$fanyi(item.label)"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$fanyi('店铺名称') + '：'" prop="shop_name">
          <el-input v-model="$parent.shopForm.shop_name" resize="none" type="textarea"></el-input>
        </el-form-item>
        <el-form-item :label="$fanyi('店铺链接') + '：'" prop="shop_link">
          <el-input v-model="$parent.shopForm.shop_link" resize="none" type="textarea"></el-input>
        </el-form-item>
        <el-form-item :label="$fanyi('备注') + '：'">
          <el-input v-model="$parent.shopForm.remark" resize="none" type="textarea"></el-input>
        </el-form-item>
        <el-form-item v-if="$parent.dialogTitle!=$fanyi('新增店铺')" :label="$fanyi('店铺状态') + '：'">
          <div class="switchContainer">
            <el-switch
                v-model="$parent.shopForm.shop_status"
                active-color="#4A91E9"
                inactive-color="#C9C9C9">
            </el-switch>
          </div>
        </el-form-item>
        <el-form-item v-if="$parent.dialogTitle!=$fanyi('新增店铺')" :label="$fanyi('授权状态') + '：'">
          <div>{{ $fanyi($parent.authorization_status) }}</div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible=false">{{ $fanyi("リセット") }}</el-button>
            <el-button type="primary" @click="save">{{ $fanyi("保存") }}</el-button>
          </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  data() {
    return {
      shopTypeOption: [{
        label: '亚马逊',
        value: 'amazon'
      }],
      rules: {
        shop_type: [{
          required: true,
          message: this.$fanyi("请选择店铺类型"),
          trigger: ["blur", "change"],
        }],
        shop_name: [{
          required: true,
          message: this.$fanyi("请输入店铺名称"),
          trigger: ["blur", "change"],
        }],
        shop_link: [{
          required: true,
          message: this.$fanyi("请输入店铺链接"),
          trigger: ["blur", "change"],
        }]
      },
      dialogVisible: false,
    };
  },
  methods: {
    save() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.$api.authorizedShopSave(this.$parent.shopForm).then((res) => {
            if (res.code != 0) return this.$message.error(res.msg);
            this.$message.success(res.msg);
            this.dialogVisible = false;
            this.$parent.getData();
          })
        }
      });
    },
    handleClose(done) {
      this.$confirm(`${this.$fanyi("确认关闭")}`).then(_ => {
        done();
        this.deleteData();
      }).catch(_ => {

      });
    },
    pageJump() {
      window.open('https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.e0c176df-3ea4-4690-98a7-16230e627780&state=stateexample&version=beta')
    }
  }
}
</script>

<style lang="scss" scoped>
.el-dialog__footer {
  padding: 0;

  button {
    border-radius: 8px;
    height: 34px;
    width: 120px;
    line-height: 34px;
    padding: 0;
    font-size: 14px;
  }

  button:first-child {
    color: #B4272B;
    border: 1px solid #B4272B;
    margin-right: 26px;
  }

  .el-button + .el-button {
    margin-left: 0;
  }
}

.btn {
  height: 30px;
  background: #B4272B;
  border-radius: 6px;
  line-height: 30px;
  cursor: pointer;
  font-size: 14px;
  color: #FFFFFF;
  padding: 0 17px;
  max-width: 135px;
}

.switchContainer {
  /deep/ input {
    display: none;
  }
}
</style>