<template>
  <div>
    <div id="EmployeesAccount">
      <div class="EmployeesAccountTitle flexAndCenter">
        <div class="fontSize20 color333 fontWeightBold">{{ $fanyi("店铺授权") }}</div>
      </div>
      <div class="orderStatusContainer">
        <div class="flexAndCenter">
          <el-tabs v-model="form.shop_type" type="card" @tab-click="form.page = 1; getData()">
            <el-tab-pane :label="$fanyi('亚马逊')" name=""></el-tab-pane>
            <!--            <el-tab-pane :label="'Base'+'（'+1+'）'" name="Base"></el-tab-pane>-->
            <!--            <el-tab-pane :label="'Shopify'+'（'+1+'）'" name="Shopify"></el-tab-pane>-->
            <!--            <el-tab-pane :label="$fanyi('乐天店铺')+'（'+1+'）'" name="乐天店铺"></el-tab-pane>-->
            <!--            <el-tab-pane :label="'ZOZO'+'（'+1+'）'" name="ZOZO"></el-tab-pane>-->
            <!--            <el-tab-pane :label="'Yahoo'+'（'+1+'）'" name="Yahoo"></el-tab-pane>-->
          </el-tabs>
          <div class="showBtn" @click="addShop">
            {{ $fanyi("新增店铺") }}
          </div>
          <div class="tabsRightContainer"></div>
        </div>
      </div>
      <div class="dataBody">
        <ul class="head flexAndCenter">
          <li class="oNO"><span class="tableHeadCon">No</span></li>
          <li class="orderNumber">
            <span :title="$fanyi('店铺名称')" class="tableHeadCon">{{ $fanyi('店铺名称') }}</span>
          </li>
          <li class="submitTime">
            <span :title="$fanyi('店铺链接')" class="tableHeadCon">{{ $fanyi("店铺链接") }}</span>
          </li>
          <li class="datePresentation">
            <span :title="$fanyi('添加日期')" class="tableHeadCon">{{ $fanyi("添加日期") }}</span>
          </li>
          <li class="datePresentation">
            <span :title="$fanyi('API授权状态')" class="tableHeadCon">{{ $fanyi("API授权状态") }}</span>
          </li>
          <li class="receiverName">
            <span :title="$fanyi('店铺状态')" class="tableHeadCon">{{ $fanyi("店铺状态") }}</span>
          </li>
          <li class="orderStatus">
            <span :title="$fanyi('备注')" class="tableHeadCon">{{ $fanyi("备注") }}</span>
          </li>
          <li class="operation">
            <span :title="$fanyi('操作')" class="tableHeadCon">{{ $fanyi("操作") }}</span>
          </li>
        </ul>
        <div v-show="tableData.length > 0">
          <div v-for="(item, index) in tableData" :key="index"
               style="border-bottom: 1px solid #DFDFDF;">
            <ul class="obody flexAndCenter" style="border-bottom:none">
              <li class="oNO">{{ index + 1 }}</li>
              <li class="orderNumber">{{ item.shop_name }}</li>
              <li class="submitTime">
                <el-link :href="item.shop_link" :underline="false"
                         class="u-line-3"
                         style="text-decoration:underline;color: #2742B4;width: 200px"
                         target="_blank">
                  {{ item.shop_link }}
                </el-link>
              </li>
              <li class="datePresentation">{{ item.created_at }}</li>
              <li class="datePresentation">
                <span v-if="item.authorization_status=='已授权'">{{ $fanyi(item.authorization_status) }}</span>
                <div v-else class="btn" @click="pageJump(item)">連結へ</div>
              </li>
              <li class="receiverName">
                <el-switch
                    v-model="item.shop_status"
                    :active-value="1"
                    :inactive-value="0"
                    active-color="#4A91E9"
                    inactive-color="#C9C9C9"
                    @change="shopStatusChange(item)">
                </el-switch>
              </li>
              <li class="orderStatus">
                <div>{{ item.remark }}</div>
              </li>
              <li class="operation">
                <button class="paymentBtn" @click="editShop(item)">
                  {{ $fanyi("编辑") }}
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div v-show="tableData.length === 0 && loadingStatus === false" class="tableDataNullContainer">
          {{ $fanyi("暂无数据") }}
        </div>
      </div>
      <div class="flexAndCenter pagination"></div>
    </div>
    <shop ref="shopRef"/>
  </div>
</template>
<script>
import shop from "./components/shop.vue";
import debounce from '@/utlis/debounce'

export default {
  data() {
    return {
      form: {
        shop_type: undefined
      },
      shopForm: {
        shop_type: '',
        shop_name: '',
        shop_link: '',
        shop_status: false,
        remark: '',
      },
      authorization_status: '未授权',
      dialogTitle: this.$fanyi("新增店铺"),
      total: 0,
      tableData: [],
      loadingStatus: null,
    };
  },
  components: {
    shop
  },
  created() {
    if (this.$route.query.spapi_oauth_code != undefined && this.$route.query.state != undefined && this.$route.query.selling_partner_id != undefined) {
      this.$api.authorizeUser({
        spapi_oauth_code: this.$route.query.spapi_oauth_code,
        state: this.$route.query.state,
        selling_partner_id: this.$route.query.selling_partner_id
      }).then((res) => {
        this.getData();
      })
    } else {
      this.getData();
    }
  },
  methods: {
    // 获取数据
    getData() {
      this.loading = this.$loading({
        lock: true,
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
      this.loadingStatus = true;
      if (this.form.shop_type == 0 || this.form.shop_type == '') {
        this.form.shop_type = undefined
      }
      this.$api.authorizedShopList(this.form).then((res) => {
        this.loading.close();
        this.loadingStatus = false;
        if (res.code != 0) {
          return
        }
        this.tableData = res.data;
        this.total = res.data.total;
        this.form.page = res.data.currentPage;
        this.loadingStatus = false;
        this.$forceUpdate();
      })
    },
    // 获取每个订单数量
    ordersSum() {
      this.$api.storageDeliverNum().then((res) => {
        if (res.data.length == 0) return
      })
    },
    pageJump(val) {
      this.$api.getAuthorizedLink({
        shop_id: val.id
      }).then((res) => {
        if (res.code != 0) return
        window.open(res.data)
      })
    },
    //新增店铺
    addShop() {
      this.shopForm = {
        shop_type: '',
        shop_name: '',
        shop_link: '',
        shop_status: false,
        remark: ''
      };
      this.authorization_status = '未授权';
      this.dialogTitle = this.$fanyi("新增店铺");
      this.$refs.shopRef.dialogVisible = true;
    },
    //编辑店铺
    editShop(item) {
      this.shopForm = {
        id: item.id,
        shop_type: item.shop_type,
        shop_name: item.shop_name,
        shop_link: item.shop_link,
        shop_status: item.shop_status == 1 ? true : false,
        remark: item.remark
      };
      this.authorization_status = item.authorization_status
      this.dialogTitle = this.$fanyi("编辑店铺");
      this.$refs.shopRef.dialogVisible = true;
    },
    //设置每页多少条
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getData()
    },
    //更新店铺状态
    shopStatusChange(item) {
      debounce(() => {
        this.$api.authorizedShopStatus({
          id: item.id
        }).then((res) => {
          if (res.code != 0) {
            item.shop_status == 0 ? item.shop_status = 1 : item.shop_status = 0
            this.$message.error(res.msg);
            return
          }
          this.$message.success(res.msg);
          this.getData();
        })
      }, 1000)
    },
    //设置当前多少页
    handleCurrentChange(val) {
      this.form.page = val;
      this.getData()
    },
  }
};
</script>
<style lang='scss' scoped>
@import "../../../../css/mixin.scss";

/deep/ input[type="checkbox"]:checked {
  background: #4A91E9;
}

.downloadBtn {
  width: 100px;
  height: 34px;
  background: #2742B4;
  border-radius: 8px;
  line-height: 34px;
  cursor: pointer;
  text-align: center;
  color: #fff;
  font-size: 14px;
  margin-top: 10px;
}

.tableDataNullContainer {
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.ordersNumber {
  width: 180px;

  .goodsTagDetailsContainer {
    width: 172px;
    height: 53px;
    overflow: hidden;

    div {
      text-align: left;
      color: #333333;
      font-size: 14px;
      width: 172px;
    }
  }
}

.goodsNo {
  width: 50px;
}

.goodsImage {
  width: 88px;
}

.goodsAttribute {
  width: 150px;
}

.goodsAttributeDetailsContainer {
  width: 119px;
  height: 48px;

  .optionListContainer,
  .detailListContainer {
    width: 119px;
    height: 56px;
    overflow: hidden;
  }

  div {
    text-align: left;
    color: #333333;
    font-size: 14px;
    width: 119px;
  }
}

.purchaseData {
  width: 120px;
}

.quantityShipped {
  width: 80px;
}

.option {
  width: 140px;
}

.goodsRemark {
  width: 114px;
}

#EmployeesAccount {
  background: #ffffff;
  border-radius: 10px;

  .EmployeesAccountTitle {
    background: #FFFFFF;
    height: 73px;
    justify-content: space-between;
    padding: 0 26px;
    border-bottom: 1px solid #DFDFDF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .tabsRightContainer {
    height: 40px;
    border-bottom: 1px solid #B4272B;
    margin-top: -14px;
    flex: 1;
  }

  /deep/ .el-tabs__header {
    border-bottom: none;
  }

  /deep/ .is-active {
    border-top: 1px solid #B4272B !important;
    border-right: 1px solid #B4272B !important;
    border-left: 1px solid #B4272B !important;
    border-bottom: 1px solid transparent !important;
  }

  /deep/ .el-tabs__item {
    border-radius: 10px 10px 0 0;
    border-top: 1px solid #DFDFDF;
    border-left: 1px solid #DFDFDF;
    border-right: 1px solid #DFDFDF;
    border-bottom: 1px solid #B4272B;
  }

  /deep/ .el-tabs__nav {
    border: none;
  }

  .orderStatusContainer {
    margin: 26px 26px 0;
    position: relative;

    .showBtn {
      height: 30px;
      padding: 0 10px;
      background: #B4272B;
      line-height: 30px;
      text-align: center;
      border-radius: 6px;
      font-size: 12px;
      color: #fff;
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: 0;
    }
  }

  .orderScreenContainer {
    margin-left: 26px;
    padding-bottom: 15px;
    margin-right: 26px;
    border-bottom: 1px solid #DFDFDF;

    .cancelBtnListContainer {
      width: 100px;
      height: 30px;
      background: #B4272B;
      border-radius: 6px;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      color: #fff;
      font-size: 12px;
      margin-left: 10px;
    }

    .orderKeywordsContainer {
      /deep/ .el-input__inner {
        width: 160px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 6px;
      }
    }

    .orderTimeContainer {
      /deep/ .el-input__inner {
        width: 176px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 6px;
        padding-left: 13px;
        padding-right: 0;
      }

      /deep/ .el-date-editor {
        .el-input__prefix {
          display: none;
        }
      }

      /deep/ .el-input__icon {
        line-height: 30px;
      }
    }

    .orderScreenBtnListContainer {
      div {
        width: 76px;
        height: 30px;
        border-radius: 6px;
        cursor: pointer;
        text-align: center;
        font-size: 12px;
        line-height: 30px;
      }

      div:first-child {
        color: #fff;
        background: #B4272B;
        margin-right: 10px;
      }

      div:last-child {
        border: 1px solid #B4272B;
        color: #B4272B;
      }
    }
  }

  .btn {
    height: 30px;
    background: #B4272B;
    border-radius: 6px;
    line-height: 30px;
    cursor: pointer;
    font-size: 14px;
    color: #FFFFFF;
    padding: 0 17px;
    max-width: 135px;
  }

  .dataBody {
    margin: 20px 26px 0;

    .oNO {
      width: 42px;
    }

    .orderNumber {
      width: 200px;
    }

    .submitTime {
      width: 200px;
    }

    .datePresentation {
      width: 120px;
    }

    .receiverName {
      width: 140px;

      /deep/ input {
        display: none;
      }
    }

    .orderStatus {
      width: 154px;
      display: flex;
      justify-content: center;

      div {
        overflow: hidden;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        width: 140px;
      }
    }

    .operation {
      width: 112px;

      .searchBtn {
        cursor: pointer;
        color: #B4272B;
        text-decoration: underline;
      }

      .paymentBtn {
        width: 80px;
        height: 26px;
        background: #B4272B;
        border-radius: 6px;
        cursor: pointer;
        text-align: center;
        line-height: 26px;
        color: #FEFEFE;
        font-size: 12px;
      }
    }

    .head {
      justify-content: center;
      background: #F0F0F0;
      font-size: 14px;
      color: #333;
      height: 56px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      text-align: center;
    }

    .obody {
      text-align: center;
      min-height: 70px;
      font-size: 14px;
      border-bottom: 1px solid #DFDFDF;
    }
  }

  .pagination {
    padding: 30px 0;
    justify-content: center;

    /deep/ .el-pagination .el-select .el-input {
      width: 110px;
    }
  }
}
</style>
